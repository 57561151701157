import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { newAttributeSort, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import { message } from "antd";
import FiltersContext from "../contexts/Filters";
import MeasuresContext from "../contexts/Measures";

import BarVehicles from "../components/Vehicles/BarVehicles";
import LineVehicles from "../components/Vehicles/LineVehicles";
import AdjustmentVehicles from "../components/Vehicles/AdjustmentVehicles";
import { useBasketContext } from "../contexts/BasketProvider";

import { MakeModelTable } from "../components/Vehicles/MakeModelTable";
import { TableVehicles } from "../components/Vehicles/TableVehicles";
import UserContext from "../contexts/User";
import { useCarSelector } from "../contexts/CarSelectorProvider";
// import BenchmarkDuration from "../components/Vehicles/BenchmarkDuration";
import { ActionButton } from "../components/Buttons/PrimaryButton";
import * as Md from "../md/full";
import MakeModelOverview from "../components/Vehicles/MakeModelOverview";
import MakeModelDetailedView from "../components/Vehicles/MakeModelDetailView";
import VatIndicator from "../components/VatIndicator";
import VehicleExport from "../components/Vehicles/VehiclesExport";

function Vehicles() {
    const { currentFilter, filterCountryId, filterChannel1, currentFilterHistory, currentFilterHistoryWithNoPeriodFrequency, getFiltersForFootnote, getFilterValue } = useContext(FiltersContext);
    const { seriesBy, selectedKpi, getScopeForTitle, getScopeForTitleHistory } = useContext(MeasuresContext);
    const { selectedVehicles, customFields, hiddenPlayersField, setSelectedVehicles, setSelectedModels } = useCarSelector();
    const { user, showVersionCatalog, kpiSettings } = useContext(UserContext);
    const { addVehicles } = useBasketContext();
    const [isVisualizeSelection, setVisualizeSelection] = useState(false);
    // State related to track the loading status of the data in the nested table
    const [loadingStatus, setLoadingStatus] = useState(undefined);
    const [showSelectedVehicles, setShowSelectedVehicles] = useState(false);
    // selectedVehicles | allVehicles | undefined
    const [exportMode, setExportMode] = useState(undefined);
    const vizRef = useRef(null);
    const titleHistory = getScopeForTitleHistory();
    const title = getScopeForTitle();
    const footnote = getFiltersForFootnote();
    const filterCustIDValues = getFilterValue("filterCustLabel");
    const [custIdFilter, setCustIdFilter] = useState(newPositiveAttributeFilter(Md.CustId, hiddenPlayersField?.players ? [...hiddenPlayersField?.players] : []));

    function addToBasket() {
        const basketVehicles = selectedVehicles.map((item) => {
            item.Vehicle = item?.Version;
            return item;
        });
        try {
            let addVehicleRes = addVehicles(basketVehicles);
            if (addVehicleRes) {
                message.info({
                    content: `Vehicle has been added to basket`,
                    style: {
                        right: "20px",
                        top: "70px",
                        position: "absolute",
                    },
                    duration: 1,
                });
            }
        } catch (e) {
            message.error({
                content: e?.message,
                style: {
                    right: "20px",
                    top: "70px",
                    position: "absolute",
                },
                duration: 1,
            });
        }
    }

    function selectMultipleModels(modeleVersions) {
        // setBasketVehicles([...modeleVersions]);
    }

    const onVisualizeSelection = useCallback(() => {
        !isVisualizeSelection && setVisualizeSelection(!isVisualizeSelection);
        vizRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, [vizRef]);

    const onClearSelection = () => {
        setSelectedVehicles([]);
        setSelectedModels([]);
        setShowSelectedVehicles(false);
    };

    const onExportSelection = () => {
        setExportMode("selectedVehicles");
    };

    const actionItems = [
        {
            formatTitle: () => (!showSelectedVehicles ? `Show selection (${selectedVehicles.length})` : "Show all"),
            handler: () => setShowSelectedVehicles(!showSelectedVehicles),
            isEnable: user?.enable_multiselection,
        },
        {
            formatTitle: () => "Add to basket",
            handler: addToBasket,
            isEnable: true,
        },
        {
            formatTitle: () => "Visualize my selection",
            handler: onVisualizeSelection,
            isEnable: true,
        },
        {
            formatTitle: () => "Export selection",
            handler: onExportSelection,
            isEnable: user?.enable_multiselection,
        },

        {
            formatTitle: () => "Clear selection",
            handler: onClearSelection,
            isEnable: true,
        },
    ];

    useEffect(() => {
        // reset selected vehicles when country and channel1 filters are updated
        setSelectedVehicles([]);
        setLoadingStatus(undefined);
        setShowSelectedVehicles(false);
    }, [filterCountryId, filterChannel1]);

    useEffect(() => {
        isVisualizeSelection && vizRef?.current.scrollIntoView();
    }, [isVisualizeSelection]);

    useEffect(() => {
        setCustIdFilter(newPositiveAttributeFilter(Md.CustId, hiddenPlayersField?.players ? [...hiddenPlayersField?.players] : []));
    }, [hiddenPlayersField]);

    return (
        <div className="flex flex-col items-stretch gap-2">
            {
                <>
                    <div className="flex items-center gap-2">
                        <h3 className="text-indigo-700">{`Car Selector - ${title}`}</h3>
                        <VatIndicator />
                    </div>
                    {/* Selection actions */}
                    {loadingStatus === "success" && selectedVehicles.length > 0 && <div className="flex items-center gap-3">{actionItems.map((actionItem) => actionItem.isEnable && <ActionButton title={actionItem.formatTitle()} onClick={() => actionItem.handler()} />)}</div>}
                </>
            }
            {showVersionCatalog || showSelectedVehicles ? (
                <TableVehicles
                    defaultElements={showSelectedVehicles && selectedVehicles}
                    showLegends={showVersionCatalog}
                    seriesBy={[...seriesBy, Md.Emissions.Avg, Md.Doors.Avg]}
                    slicesBy={[Md.VersionStd, Md.CustId, Md.FontBackColor, Md.CorrModel, Md.VehSegment, Md.BodyStyle, Md.GearsPlus, Md.Fuel, Md.CorrMake, Md.VehType]}
                    filters={currentFilter}
                    kpi={selectedKpi}
                    onSelectVehicle={(vehicle) => {
                        // setBasketVehicles([vehicle]);
                    }}
                    onLoadData={() => setLoadingStatus("success")}
                    isPaginated={true}
                    footnote={footnote}
                    enableMultiSelection={user?.enable_multiselection}
                />
            ) : (
                <MakeModelTable onSelectMultipleRows={selectMultipleModels} footnote={footnote} onLoadData={() => setLoadingStatus("success")} enableMultiselection={user?.enable_multiselection} />
            )}
            {exportMode !== undefined && (
                <VehicleExport
                    seriesBy={seriesBy}
                    slicesBy={[Md.VersionStd, Md.CustId, Md.FontBackColor, Md.CorrModel]}
                    filters={[...currentFilter, newPositiveAttributeFilter(Md.VersionStd, exportMode === "selectedVehicles" ? [...selectedVehicles?.map((item) => item?.Version)] : [])]}
                    kpi={selectedKpi}
                    onDownloadCallback={() => {
                        setExportMode(undefined);
                    }}
                />
            )}
            {selectedVehicles?.length > 0 && (
                <section ref={vizRef}>
                    {selectedVehicles?.length === 1 ? (
                        <>
                            <div className="mt-8">
                                <div className="flex items-center gap-2">
                                    <h3 className="text-indigo-700">{`Rentals - ${title}`}</h3>
                                    <VatIndicator />
                                </div>
                                <h4 className="text-indigo-700">{selectedVehicles?.[0]?.Version}</h4>
                                <BarVehicles
                                    seriesBy={seriesBy}
                                    slicesBy={[Md.CustId, Md.Channel3]}
                                    filters={[...currentFilter, newPositiveAttributeFilter(Md.VersionStd, [...selectedVehicles?.map((item) => item?.Version)]), filterCustIDValues.length <= 0 ? custIdFilter : null]}
                                    kpiSettings={kpiSettings}
                                    customFields={customFields}
                                />
                                <p className="text-left text-xs font-medium">{footnote}</p>
                            </div>
                            <div className="mt-8">
                                <div className="flex items-center gap-2">
                                    <h3 className="text-indigo-700">{`Rental Trends - ${titleHistory}`}</h3>
                                    <VatIndicator />
                                </div>
                                <h4 className="text-indigo-700">{selectedVehicles?.[0]?.Version}</h4>
                                <LineVehicles
                                    seriesBy={[...seriesBy, Md.CustId]}
                                    slicesBy={[Md.DateDatasets.DateOfScraping.Date.YyyyMmDd]}
                                    filters={[...currentFilterHistoryWithNoPeriodFrequency, newPositiveAttributeFilter(Md.VersionStd, [...selectedVehicles?.map((item) => item?.Version)]), filterCustIDValues.length <= 0 ? custIdFilter : null]}
                                    kpiSettings={kpiSettings}
                                    customFields={customFields}
                                    sortBy={[newAttributeSort(Md.DateDatasets.DateOfScraping.Date.YyyyMmDd, "asc")]}
                                />
                                <p className="text-left text-xs font-medium">{footnote}</p>
                            </div>

                            {/* <div className="mt-8">
                            <h3 className="text-indigo-700">{`Benchmark Duration - ${title}`}</h3>
                            <h4 className="text-indigo-700">{selectedVehicles[0].Version}</h4>
                            <BenchmarkDuration seriesBy={seriesBy} filters={[...currentFilter.slice(0, 5), ...currentFilter.slice(6), newPositiveAttributeFilter(Md.VersionStd, [...selectedVehicles?.map((item) => item.Version)])]} kpi={selectedKpi} />
                            <p className="mt-5 text-left text-xs font-medium">{footnote}</p>
                        </div> */}

                            <div className="mt-8">
                                <div className="flex items-center gap-2">
                                    <h3 className="text-indigo-700">{`Rental Breakdown - ${title}`}</h3>
                                    <VatIndicator />
                                </div>
                                <h4 className="text-indigo-700">{selectedVehicles[0]?.Version}</h4>
                                <AdjustmentVehicles seriesBy={seriesBy} filters={[...currentFilter, newPositiveAttributeFilter(Md.VersionStd, [...selectedVehicles?.map((item) => item?.Version)]), filterCustIDValues.length <= 0 ? custIdFilter : null]} />
                                <p className="mt-5 text-left text-xs font-medium">{footnote}</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="mt-8">
                                <h3 className="text-indigo-700">{`Synthesis Graph | ${title}`}</h3>
                                <MakeModelOverview
                                    seriesBy={seriesBy}
                                    filters={[...currentFilter, newPositiveAttributeFilter(Md.VersionStd, [...selectedVehicles?.map((item) => item?.Version)]), filterCustIDValues.length <= 0 ? custIdFilter : null]}
                                    footnote={footnote}
                                    title={`Synthesis Graph | ${title}`}
                                    kpiSettings={kpiSettings}
                                    customFields={customFields}
                                />
                            </div>
                            <div className="mt-8">
                                <h3 className="text-indigo-700">{`Detailed View | ${titleHistory}`}</h3>
                                <MakeModelDetailedView filters={[...currentFilter, newPositiveAttributeFilter(Md.VersionStd, [...selectedVehicles?.map((item) => item?.Version)]), filterCustIDValues.length <= 0 ? custIdFilter : null]} footnote={footnote} />
                            </div>
                        </>
                    )}
                </section>
            )}
        </div>
    );
}

const tableLegendItems = [
    {
        label: "Regular Rental",
        color: "#000000",
    },
    {
        label: "From Previous Month(s)",
        color: "#D9D9D9",
        isbackground: true,
    },
    {
        label: "Special Promotion",
        color: "#FF0000",
    },
    {
        label: "PCP Rental",
        color: "#0070C0",
    },
    {
        label: "Extrap. from similar version",
        color: "#FCE4D6",
        isbackground: true,
    },
];

export { Vehicles, tableLegendItems };
