import React from "react";
import ProgressBar from "./ProgressBar";

const LoadingPopup = ({ isOpen, message, progress }) => {
    if (!isOpen) return null;

    const getProgressMessage = (progress) => {
        if (progress < 5) return "Initializing document...";
        if (progress < 40) return "Processing data and creating pages...";
        if (progress < 70) return "Generating tables and formatting...";
        if (progress < 85) return "Assembling PDF document...";
        if (progress < 95) return "Compressing and preparing download...";
        return "Finalizing...";
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 animate-fadeIn">
            <div className="bg-white p-8 rounded-lg shadow-xl max-w-sm w-full mx-4 animate-slideUp">
                <div className="flex flex-col items-center gap-6">
                    <h3 className="text-lg font-semibold text-gray-700 flex items-center">
                        {message}
                        <span className="flex ml-2">
                            <span className="w-1 h-1 mt-2 bg-indigo-700 rounded-full animate-dot1" />
                            <span className="w-1 h-1 mt-2 bg-indigo-700 rounded-full ml-1 animate-dot2" />
                            <span className="w-1 h-1 mt-2 bg-indigo-700 rounded-full ml-1 animate-dot3" />
                        </span>
                    </h3>

                    <div className="w-full">
                        <ProgressBar progress={progress} />
                    </div>

                    <div className="text-sm text-gray-500 flex flex-col items-center">
                        <p className="text-xs font-medium">{progress.toFixed(0)}% Complete</p>
                        <p className="text-xs mt-2 font-medium transition-all duration-300 ease-in-out">{getProgressMessage(progress)}</p>
                        {progress >= 85 && <p className="text-xs mt-3 font-medium text-indigo-700 animate-fadeIn">* This may take a few moments for larger documents...</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingPopup;
