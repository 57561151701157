import { newMeasureValueFilter, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import { ErrorComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import React, { useEffect, useState, useContext } from "react";
import { useAppContext } from "../../contexts/AppContext";
import FiltersContext from "../../contexts/Filters";
import MeasuresContext from "../../contexts/Measures";
import * as Md from "../../md/full";
import { useQuery } from "@apollo/client";
import { ENABLED_SETTINGS, GET_KPI_ADJ_SETTINGS } from "../../queries/Users";
import LoadingSpinner from "../LoadingSpinner";
import Widget from "../Widget";
import Exportbtn from "../Export/Exportbtn";
import Export from "./Export";
import ComboBoxScopeFilter from "../ComboBoxScopeFilter";
import ExtendedTable from "./ExtendedTable";
import CondensedTable from "./CondensedTable";
import { generatePdf } from "../../utils/pdfGenerator";
import LoadingPopup from "../LoadingPopup";
import { notification } from "antd";

const UKOEM = () => {
    const [offers, setOffers] = useState([]);
    const [bestOffers, setBestOffers] = useState([]);
    const [AprNull, setAprNull] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [exportData, setExportData] = useState([]);
    const { getCountryByCode, showExtendedTable } = useAppContext();
    const { getFiltersForFootnote } = useContext(FiltersContext);
    const { seriesBy } = useContext(MeasuresContext);
    const {
        filterMthCode,
        filterCountryId,
        filterChannel1,
        filterChannel3,
        filterMake,
        filterModel,
        filterFuel,
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterListPrice,
        filterPs,
        filterKw,
        filterCo2,
        filterCustmix,
        filterRolledForward,
        filterVersionStd,
        filterDuration,
        getFirstFilterValue,
        updateFilter,
        currentFilter,
    } = useContext(FiltersContext);

    const commonFilters = [
        filterMthCode,
        filterCountryId,
        filterChannel1,
        filterChannel3,
        newPositiveAttributeFilter(Md.Extraptype, ["No Extrapolation"]),
        filterMake,
        filterModel,
        filterFuel,
        newPositiveAttributeFilter(Md.CustId, ["3PO"]),
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterListPrice,
        filterPs,
        filterKw,
        filterCo2,
        filterCustmix,
        filterRolledForward,
        filterVersionStd,
    ];

    const oemFilters = [...commonFilters, filterDuration, ...(AprNull ? [newMeasureValueFilter(Md.Apr.Avg, "EQUAL_TO", 0)] : [])];

    const exportfilters = [...commonFilters, ...(AprNull ? [newMeasureValueFilter(Md.Apr.Avg, "EQUAL_TO", 0)] : [])];
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: [...seriesBy, Md.Deposit.Avg, Md.Doors.Avg, Md.Apr.Avg],
            // seriesBy: [Md.ListPrice.Avg, Md.Rental.Avg, Md.Deposit.Avg, Md.Co2malus.Avg, Md.Kw.Avg, Md.Ps.Avg, seriesBy],
            slicesBy: [Md.VersionStd, Md.CorrMake, Md.Fuel, Md.Channel3, Md.Duration, Md.Channel2, Md.CorrModel, Md.VehSegment, Md.BodyStyle, Md.TrimName, Md.Version, Md.GearsPlus, Md.Drive, Md.Warning, Md.Url],
            filters: [...oemFilters],
        },
    });
    const columns = [
        {
            title: "Vehicle",
            key: "Vehicle",
            type: "String",
        },
        {
            title: "List_Price",
            key: "List_Price",
            type: "Numeric",
        },
        {
            title: "Channel",
            key: "Channel",
            type: "String",
        },
        {
            title: "Finance_Type",
            key: "Finance_Type",
            type: "String",
        },
        {
            title: "Month",
            key: "Month",
            type: "Numeric",
        },
        {
            title: "Mileage",
            key: "Mileage",
            type: "Numeric",
        },
        {
            title: "Web_Rental",
            key: "Web_Rental",
            type: "Numeric",
        },
        {
            title: "Deposit",
            key: "Deposit",
            type: "Numeric",
        },
        {
            title: "Interest_Rate",
            key: "Interest_Rate",
            type: "Numeric",
        },
        {
            title: "RV",
            key: "RV",
            type: "Numeric",
        },
        {
            title: "Adj_Rental",
            key: "Adj_Rental",
            type: "Numeric",
        },
        {
            title: "Leasing_Factor",
            key: "Leasing_Factor",
            type: "Numeric",
        },
        {
            title: "Web_Rental_Includes",
            key: "Web_Rental_Includes",
            type: "String",
        },
        {
            title: "Adj_Rental_Includes",
            key: "Adj_Rental_Includes",
            type: "String",
        },
        {
            title: "Make",
            key: "Make",
            type: "String",
        },
        {
            title: "Model",
            key: "Model",
            type: "String",
        },
        {
            title: "Energy",
            key: "Energy",
            type: "String",
        },
        {
            title: "Trim",
            key: "Trim",
            type: "String",
        },
        {
            title: "Segment",
            key: "Segment",
            type: "String",
        },
        {
            title: "Body_Type",
            key: "Body_Type",
            type: "String",
        },
        {
            title: "Gearbox",
            key: "Gearbox",
            type: "String",
        },
        {
            title: "Doors",
            key: "Doors",
            type: "Numeric",
        },
        {
            title: "Power",
            key: "Power",
            type: "Numeric",
        },
        {
            title: "Transmission",
            key: "Transmission",
            type: "String",
        },
        {
            title: "Version",
            key: "Version",
            type: "String",
        },
        {
            title: "Comment",
            key: "Comment",
            type: "String",
        },
    ];
    function sortColumn(column, sortOrder) {
        let comparator;
        if (column.type === "String") {
            comparator = sortOrder === "asc" ? (a, b) => a[column.key].localeCompare(b[column.key]) : (a, b) => b[column.key].localeCompare(a[column.key]);
        } else {
            comparator = sortOrder === "asc" ? (a, b) => a[column.key] - b[column.key] : (a, b) => b[column.key] - a[column.key];
        }
        const sortedData = offers.sort(comparator);
        setOffers([...sortedData]);
    }
    function sortBestOffers(column, sortOrder) {
        let comparator;
        if (column.type === "String") {
            comparator = sortOrder === "asc" ? (a, b) => a[column.key].localeCompare(b[column.key]) : (a, b) => b[column.key].localeCompare(a[column.key]);
        } else {
            comparator = sortOrder === "asc" ? (a, b) => a[column.key] - b[column.key] : (a, b) => b[column.key] - a[column.key];
        }
        const sortedData = bestOffers.sort(comparator);
        setBestOffers([...sortedData]);
    }
    // Service Settings included in adj Rental
    const settingsAliases = {
        flag_ser: "Maintenance",
        flag_bre: "Breakdown",
        flag_ins: "Insurance",
        flag_rep: "Replacement",
        flag_fin: "Financial loss",
        flag_co2: "CO2 Malus",
        flag_tyr: "Tyres",
        flag_win: "Winter Tyres",
        flag_evb: "EV Bonus",
    };
    const { data: kpiSettingsResults } = useQuery(GET_KPI_ADJ_SETTINGS, {
        variables: {
            ctryid: filterCountryId.positiveAttributeFilter.in["values"][0],
            channel1: filterChannel1.positiveAttributeFilter.in["values"][0],
        },
    });
    const Enabled = useQuery(ENABLED_SETTINGS, {
        variables: { ctryid: filterCountryId.positiveAttributeFilter.in["values"][0], channel1: filterChannel1.positiveAttributeFilter.in["values"][0] },
    }).data?.re_rentaleye_re_services_settings[0];
    const settings = kpiSettingsResults?.re_rentaleye_re_params_user_prefs.map((kpi) => {
        return {
            [settingsAliases.flag_ser]: Enabled?.flag_ser ? (kpi.flag_ser ? "Y" : "N") : "",
            [settingsAliases.flag_bre]: Enabled?.flag_bre ? (kpi.flag_bre ? "Y" : "N") : "",
            [settingsAliases.flag_ins]: Enabled?.flag_ins ? (kpi.flag_ins ? "Y" : "N") : "",
            [settingsAliases.flag_rep]: Enabled?.flag_rep ? (kpi.flag_rep ? "Y" : "N") : "",
            [settingsAliases.flag_fin]: Enabled?.flag_fin ? (kpi.flag_fin ? "Y" : "N") : "",
            [settingsAliases.flag_co2]: Enabled?.flag_co2 ? (kpi.flag_co2 ? "Y" : "N") : "",
            [settingsAliases.flag_tyr]: Enabled?.flag_tyr ? (kpi.flag_tyr ? "Y" : "N") : "",
            [settingsAliases.flag_win]: Enabled?.flag_win ? (kpi.flag_win ? "Y" : "N") : "",
            [settingsAliases.flag_evb]: Enabled?.flag_evb ? (kpi.flag_evb ? "Y" : "N") : "",
        };
    });

    const services = settings && Object.keys(settings[0]).filter((key) => settings[0][key] === "Y");
    const ServicesMessage = services?.length > 0 ? `${services.join(", ")}` : "No Services";

    useEffect(() => {
        //First Part of Data
        setIsLoading(true);
        const slices = result?.data()?.slices()?.toArray();
        if (slices) {
            const data = slices.map((slice) => {
                const services = {
                    Maintenance: Number(slice.dataPoints()[5].rawValue),
                    Tyres: Number(slice.dataPoints()[7].rawValue),
                    Breakdown: Number(slice.dataPoints()[9].rawValue),
                    Replacement: Number(slice.dataPoints()[11].rawValue),
                    Insurance: Number(slice.dataPoints()[13].rawValue),
                    "Financial Loss": Number(slice.dataPoints()[15].rawValue),
                    "Winter Tyres": Number(slice.dataPoints()[17].rawValue),
                    "EV Bonus": Number(slice.dataPoints()[19].rawValue),
                    "Co2 Malus": Number(slice.dataPoints()[21].rawValue),
                };
                return {
                    Vehicle: slice.sliceTitles()[0],
                    List_Price: Number(slice.dataPoints()[1].rawValue) > 0 ? Number(Number(slice.dataPoints()[1].rawValue).toFixed(0)) : null,
                    Channel: slice.sliceTitles()[5],
                    Finance_Type: slice.sliceTitles()[3],
                    Month: Number(slice.sliceTitles()[4].split("/")[0]),
                    Mileage: Number(slice.sliceTitles()[4].split("/")[1]),
                    Web_Rental: Number(slice.dataPoints()[3].rawValue) > 0 ? Number(Number(slice.dataPoints()[3].rawValue).toFixed(0)) : null,
                    Deposit: Number(slice.dataPoints()[33].rawValue) > 0 ? Number(Number(slice.dataPoints()[33].rawValue).toFixed(0)) : null,
                    Interest_Rate: slice.dataPoints()[35].rawValue === "0" ? 0 + "%" : Number(slice.dataPoints()[35].rawValue) > 0 ? Number(100 * Number(slice.dataPoints()[35].rawValue)).toFixed(2) + "%" : null,
                    RV: Number(slice.dataPoints()[25].rawValue) > 0 ? Number(Number(slice.dataPoints()[25].rawValue).toFixed(0)) : null,
                    Web_Rental_Includes:
                        Object.entries(services)
                            .filter((m) => m[1] === 1)
                            .map((m) => m[0])
                            .join(", ") || "No Services",
                    Adj_Rental: Number(slice.dataPoints()[23].rawValue) > 0 ? Number(Number(slice.dataPoints()[23].rawValue).toFixed(0)) : null,
                    Leasing_Factor: Number(slice.dataPoints()[1].rawValue) > 0 ? Number((100 * Number(slice.dataPoints()[24].rawValue)).toFixed(2)) : null,
                    Adj_Rental_Includes: ServicesMessage,
                    Make: slice.sliceTitles()[1],
                    Model: slice.sliceTitles()[6],
                    Energy: slice.sliceTitles()[2],
                    Trim: slice.sliceTitles()[9],
                    Segment: slice.sliceTitles()[7],
                    Body_Type: slice.sliceTitles()[8],
                    Gearbox: slice.sliceTitles()[11],
                    Doors: Number(slice.dataPoints()[34].rawValue),
                    Power: Number(slice.dataPoints()[22].rawValue),
                    Transmission: slice.sliceTitles()[12],
                    Version: slice.sliceTitles()[10],
                    Comment: slice.sliceTitles()[13],
                    Url: slice.sliceTitles()[14],
                };
            });
            // find duplicates versions
            const duplicates = data.filter((item, index) => data.findIndex((i) => i.Vehicle === item.Vehicle) !== index);
            // remove duplicates that have no ajusted rental or leasing factor
            const dataWithoutDuplicates = data.filter((item) => duplicates.findIndex((i) => i.Vehicle === item.Vehicle) === -1 || (item["Adj. Rental"] !== null && item["Leasing Factor"] !== null));

            const datatoExport = slices.map((slice) => {
                const services = {
                    Maintenance: Number(slice.dataPoints()[5].rawValue),
                    Tyres: Number(slice.dataPoints()[7].rawValue),
                    Breakdown: Number(slice.dataPoints()[9].rawValue),
                    Replacement: Number(slice.dataPoints()[11].rawValue),
                    Insurance: Number(slice.dataPoints()[13].rawValue),
                    "Financial Loss": Number(slice.dataPoints()[15].rawValue),
                    "Winter Tyres": Number(slice.dataPoints()[17].rawValue),
                    "EV Bonus": Number(slice.dataPoints()[19].rawValue),
                    "Co2 Malus": Number(slice.dataPoints()[21].rawValue),
                };
                return {
                    Vehicle: slice.sliceTitles()[0],
                    List_Price: Number(slice.dataPoints()[1].rawValue) > 0 ? Number(Number(slice.dataPoints()[1].rawValue).toFixed(0)) : null,
                    Channel: slice.sliceTitles()[5],
                    Finance_Type: slice.sliceTitles()[3],
                    Month: Number(slice.sliceTitles()[4].split("/")[0]),
                    Mileage: Number(slice.sliceTitles()[4].split("/")[1]),
                    Web_Rental: Number(slice.dataPoints()[3].rawValue) > 0 ? Number(Number(slice.dataPoints()[3].rawValue).toFixed(0)) : null,
                    Deposit: Number(slice.dataPoints()[33].rawValue) > 0 ? Number(Number(slice.dataPoints()[33].rawValue).toFixed(0)) : null,
                    "Interest Rate": slice.dataPoints()[35].rawValue === "0" ? 0 + "%" : Number(slice.dataPoints()[35].rawValue) > 0 ? Number(100 * Number(slice.dataPoints()[35].rawValue)).toFixed(2) + "%" : null,
                    RV: Number(slice.dataPoints()[25].rawValue) > 0 ? Number(Number(slice.dataPoints()[25].rawValue).toFixed(0)) : null,
                    "Web Rental Includes":
                        Object.entries(services)
                            .filter((m) => m[1] === 1)
                            .map((m) => m[0])
                            .join(", ") || "No Services",
                    Adj_Rental: Number(slice.dataPoints()[23].rawValue) > 0 ? Number(Number(slice.dataPoints()[23].rawValue).toFixed(0)) : null,
                    Leasing_Factor: Number(slice.dataPoints()[1].rawValue) > 0 ? Number((100 * Number(slice.dataPoints()[24].rawValue)).toFixed(2)) : null,
                    Adj_Rental_Includes: ServicesMessage,
                    Make: slice.sliceTitles()[1],
                    Model: slice.sliceTitles()[6],
                    Energy: slice.sliceTitles()[2],
                    Trim: slice.sliceTitles()[9],
                    Segment: slice.sliceTitles()[7],
                    Body_Type: slice.sliceTitles()[8],
                    GearBox: slice.sliceTitles()[11],
                    Doors: Number(slice.dataPoints()[34].rawValue),
                    Power: Number(slice.dataPoints()[22].rawValue),
                    Transmission: slice.sliceTitles()[12],
                    Version: slice.sliceTitles()[10],
                    Comment: slice.sliceTitles()[13],
                    Url: slice.sliceTitles()[14],
                };
            });
            setExportData(datatoExport);

            setOffers(dataWithoutDuplicates);
            //Best Offers >> Conditions : Leasing Factor > 0, lowest 5 leasing factor offers, segment lenght = 1
            const bestOffers = dataWithoutDuplicates
                ?.filter((item) => item["Leasing_Factor"] > 0 && item.Segment.length === 1)
                .sort((a, b) => a["Leasing_Factor"] - b["Leasing_Factor"])
                .slice(0, 5);
            setBestOffers(bestOffers);
            setIsLoading(false);
        }
    }, [result, AprNull]);

    const stickyColumn = "Vehicle";
    const columnGroups = [
        { title: "", span: 1, className: "sticky left-0 bg-gray-100" },
        {
            title: "Promotion",
            span: 11,
            className: "px-4 py-2 text-xs text-white bg-indigo-700",
        },
        {
            title: "Promotion Details",
            span: 14,
            className: "px-4 py-2 text-xs text-white bg-indigo-500",
        },
    ];
    const CondensedColumns = [
        {
            title: "Vehicle",
            key: "Vehicle",
            type: "String",
        },
        {
            title: "List_Price",
            key: "List_Price",
            type: "Numeric",
        },
        {
            title: "Channel",
            key: "Channel",
            type: "String",
        },
        {
            title: "Finance_Type",
            key: "Finance_Type",
            type: "String",
        },
        {
            title: "Month",
            key: "Month",
            type: "Numeric",
        },
        {
            title: "Mileage",
            key: "Mileage",
            type: "Numeric",
        },
        {
            title: "Web_Rental",
            key: "Web_Rental",
            type: "Numeric",
        },
        {
            title: "Deposit",
            key: "Deposit",
            type: "Numeric",
        },
        {
            title: "Adj_Rental",
            key: "Adj_Rental",
            type: "Numeric",
        },
        {
            title: "Web_Rental_Includes",
            key: "Web_Rental_Includes",
            type: "String",
        },
        {
            title: "Adj_Rental_Includes",
            key: "Adj_Rental_Includes",
            type: "String",
        },
        {
            title: "Leasing_Factor",
            key: "Leasing_Factor",
            type: "Numeric",
        },
    ];

    const [isGenerating, setIsGenerating] = useState(false);
    const [pdfProgress, setPdfProgress] = useState(0);

    const handleGeneratePdf = async () => {
        try {
            setIsGenerating(true);
            setPdfProgress(0);
            //@ts-ignore
            const title = `Promotions — ${getCountryByCode(filterCountryId.positiveAttributeFilter.in?.values[0])} | ${filterChannel1?.positiveAttributeFilter?.in["values"][0]} | ${filterMthCode?.positiveAttributeFilter?.in["values"][0]}`;
            const subtitle = `OEM promotional offers of the month as published on the websites${getFirstFilterValue("filterDuration") ? ` (filtered by ${getFirstFilterValue("filterDuration")} months)` : ""}${AprNull ? " with APR = 0%" : ""}`;

            await generatePdf({
                data: offers,
                title,
                subtitle,
                onProgress: (progress) => setPdfProgress(progress),
            });
        } catch (error) {
            notification.error({
                message: "PDF Generation Failed",
                description: error.message || "Please try again with fewer records or contact support.",
            });
        } finally {
            setIsGenerating(false);
            setPdfProgress(0);
        }
    };

    return (
        <div>
            <div className="flex flex-row items-center mb-8">
                <div className="flex flex-row  gap-8 items-center">
                    <div className="flex flex-row gap-4 items-center">
                        <span className="font-bold text-indigo-700">Duration:</span>
                        <ComboBoxScopeFilter title={"Duration"} value={getFirstFilterValue("filterDuration")} filterAttribute={filterDuration} mdAttribute={Md.Duration} filterID={"filterDuration"} currentFilter={currentFilter} onUpdateFilter={updateFilter} />
                    </div>
                    <div className="flex flex-row gap-4 items-center">
                        <input type="checkbox" className="accent-indigo-700 cursor-pointer w-4 h-4" checked={AprNull} onChange={() => setAprNull(!AprNull)} />
                        <span className="font-medium text-gray-500 text-medium cursor-pointer hover:opacity-80" onClick={() => setAprNull(!AprNull)}>
                            {"  "}
                            Filter with APR ( Interest Rate ) = 0 %{" "}
                        </span>{" "}
                    </div>
                    {!showExtendedTable && (
                        <button onClick={handleGeneratePdf} disabled={isGenerating} className="flex text-white bg-indigo-700 px-12 py-1 border hover:bg-indigo-500 border-indigo-700 rounded font-semibold shadow-button disabled:opacity-50 disabled:cursor-not-allowed">
                            {isGenerating ? "Generating..." : "Generate PDF"}
                        </button>
                    )}
                    <Exportbtn onclick={() => setModalVisible(true)} />
                </div>
            </div>
            {isLoading && <LoadingSpinner />}
            {status === "error" && <ErrorComponent message={`No Data available for your current filters`} description="If 'Promotions' is not activated in your workspace, Please contact experteye support." />}
            {status === "success" && isLoading === false && (
                <div className="flex flex-col gap-8">
                    {/* // best offers table */}
                    {showExtendedTable && (
                        <div>
                            <Widget
                                //@ts-ignore
                                title={`Top 5 promotions of the month — ${getCountryByCode(filterCountryId.positiveAttributeFilter.in?.values[0])} | ${filterChannel1?.positiveAttributeFilter?.in["values"][0]} | ${filterMthCode?.positiveAttributeFilter?.in["values"][0]}`}
                                description="This section share the best 5 offers of the month as published on the websites"
                                footnote={getFiltersForFootnote()}
                            >
                                {/*@ts-ignore*/}
                                {showExtendedTable ? (
                                    <ExtendedTable data={bestOffers} columns={columns} columnGroups={columnGroups} onSort={sortBestOffers} stickyColumn={stickyColumn} />
                                ) : (
                                    <CondensedTable columns={CondensedColumns} data={bestOffers} onSort={sortBestOffers} stickyColumn={stickyColumn} />
                                )}
                            </Widget>
                        </div>
                    )}
                    {/* // all offers table */}
                    <Widget
                        //@ts-ignore
                        title={`Promotions — ${getCountryByCode(filterCountryId.positiveAttributeFilter.in?.values[0])} | ${filterChannel1?.positiveAttributeFilter?.in["values"][0]} | ${filterMthCode?.positiveAttributeFilter?.in["values"][0]}`}
                        description={"This section shares OEM promotional offers of the month as published on the websites"}
                        footnote={getFiltersForFootnote()}
                    >
                        {/*@ts-ignore*/}
                        {showExtendedTable ? <ExtendedTable data={offers} columns={columns} columnGroups={columnGroups} onSort={sortColumn} stickyColumn={stickyColumn} /> : <CondensedTable columns={CondensedColumns} data={offers} onSort={sortColumn} stickyColumn={stickyColumn} />}
                    </Widget>
                </div>
            )}
            <Export filters={exportfilters} seriesBy={seriesBy} data={exportData} modalVisible={modalVisible} setModalVisible={setModalVisible} />
            <LoadingPopup isOpen={isGenerating} message="Generating PDF Report" progress={pdfProgress} />
        </div>
    );
};

export default UKOEM;
