import React, { useContext, useEffect, useState } from "react";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";

import * as Md from "../../md/full";
import MeasuresContext from "../../contexts/Measures";
import { newAttributeSort } from "@gooddata/sdk-model";
import { BrandColors, CustIDByScope } from "../../contexts/BrandConfiguratorProvider";
import HighchartsComponent from "../HighchartsComponent";
import FiltersContext from "../../contexts/Filters";
import { calculateModelAvg } from "../../utils/calculationUtils";
import { Tooltip } from "antd";
import { BsInfoCircle } from "react-icons/bs";
import { calculationModes } from "../../routes/BrandModelAnalysis";

const widgetId = "brand-trends-chart";

const TrendBrandAnalysis = ({ filters, calculationMode = "average", ...props }) => {
    const { seriesBy, selectedKpi } = useContext(MeasuresContext);
    const { getFirstFilterValue } = useContext(FiltersContext);
    const [monthList, setMonthList] = useState([]);
    // const { currentFilter, getFirstFilterValue } = useContext(FiltersContext);

    const countryId = getFirstFilterValue("filterCountryId");
    const channel1 = getFirstFilterValue("filterChannel1");
    const custIDType = CustIDByScope[`${countryId}-${channel1}`];

    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy: [Md.CorrMake, Md.CorrModel, Md.CustId, Md.MthCode, Md.VersionStd],
            filters,
            sortBy: [newAttributeSort(Md.MthCode, "asc")],
        },
    });
    const [graphSeries, setGraphSeries] = useState([]);

    useEffect(() => {
        const slices = result?.data().slices().toArray();

        const dataByBrand = slices?.reduce((acc, slice) => {
            const [make, model, custId, monthCode, versionStd] = slice.sliceTitles();
            const kpiValue = slice.dataPoints()[selectedKpi.index].rawValue !== null ? Number(slice.dataPoints()[selectedKpi.index].rawValue) : null;
            const obj = {
                versionStd: versionStd,
                custId: custId,
                value: kpiValue,
            };
            const custIdType = Object.keys(custIDType).find((key) => custIDType[key].includes(custId));

            if (make in acc) {
                if (custIdType) {
                    if (custIdType in acc[make]) {
                        if (monthCode in acc[make][custIdType]) {
                            acc[make][custIdType][monthCode][model] = model in acc[make][custIdType][monthCode] ? [...acc[make][custIdType][monthCode][model], obj] : [obj];
                        } else {
                            acc[make][custIdType] = {
                                ...acc[make][custIdType],
                                [monthCode]: {
                                    [model]: [obj],
                                },
                            };
                        }
                    } else {
                        acc[make][custIdType] = {
                            [monthCode]: {
                                [model]: [obj],
                            },
                        };
                    }
                }
            } else {
                acc = {
                    ...acc,
                    [make]: {
                        [custIdType]: {
                            [monthCode]: {
                                [model]: [obj],
                            },
                        },
                    },
                };
            }
            return acc;
        }, {});
        // Getting the month list
        const monthCodeArr = slices
            ?.reduce((acc, slice) => {
                const monthCode = slice.sliceTitles()[3];
                if (!acc.includes(monthCode)) acc = [...acc, monthCode];
                return acc;
            }, [])
            .sort((a, b) => a.localeCompare(b));
        setTimeout(() => {
            if (dataByBrand && monthCodeArr) {
                // Calculate avg
                const dataPoints = Object.entries(dataByBrand).reduce((acc, [brand, promoConfigObj]) => {
                    acc[brand] = Object.entries(promoConfigObj).reduce((accPromoConfig, [promoConfig, monthsObj]) => {
                        accPromoConfig[promoConfig] = monthCodeArr.reduce((accMonth, monthCode) => {
                            const sumValues =
                                monthCode in monthsObj
                                    ? Object.values(monthsObj[monthCode]).reduce((accModel, versionsArr) => {
                                          accModel += calculateModelAvg(
                                              versionsArr.filter((obj) => obj.value !== null),
                                              custIDType[promoConfig],
                                              calculationModes[calculationMode].calculationMethod,
                                          );
                                          // calculationHandler(versionsArr.map(versionObj => versionObj.value));
                                          return accModel;
                                      }, 0)
                                    : null;
                            // Get the models count who contains a value in a single month
                            const dpCount = monthCode in monthsObj ? Object.keys(monthsObj[monthCode]).filter((model) => monthsObj[monthCode][model].find((obj) => obj.value !== null)).length : null;
                            accMonth[monthCode] = sumValues !== null ? (selectedKpi.id === 4 ? Number(((sumValues / dpCount) * 100).toFixed(2)) : selectedKpi.roundValue(sumValues / dpCount)) : null;
                            return accMonth;
                        }, {});

                        return accPromoConfig;
                    }, {});
                    return acc;
                }, {});

                // Prepare the data to be plotted
                const gSeries = Object.keys(dataPoints).reduce((acc, brand) => {
                    const brandSerie = Object.keys(dataPoints[brand]).map((promoConfig) =>
                        Object.assign({
                            name: `${brand}-${promoConfig}`,
                            data: Object.values(dataPoints[brand][promoConfig]),
                            color: BrandColors[brand],
                            dashStyle: promoConfig === "promo" ? "Dash" : "Solid",
                        }),
                    );
                    return [...acc, ...brandSerie];
                }, []);

                setMonthList(monthCodeArr);
                setGraphSeries(gSeries);
            }
        }, 100);
        return () => {
            setGraphSeries([]);
            setMonthList([]);
        };
    }, [result, selectedKpi, calculationMode]);

    return status === "loading" || monthList.length === 0 ? (
        <div className="flex flex-col items-center justify-center gap-2 min-h-[400px]">
            <div>
                <LoadingComponent />
            </div>
            <span>Loading trends...</span>
        </div>
    ) : (
        <div className="relative flex flex-col gap-2">
            {!props.widgetUid && (
                <Tooltip title={"Brand Average are calculated as  the average of average Rental by Model"} color="#6B50B6" placement="topLeft" overlayStyle={{ maxWidth: "300px" }}>
                    <span className="absolute top-2 left-2 z-10 self-start">
                        <BsInfoCircle className="w-4 h-4 text-indigo-500 cursor-help" />
                    </span>
                </Tooltip>
            )}
            <TrendChart
                series={graphSeries}
                categories={monthList}
                filters={filters}
                id={widgetId}
                section={""}
                metadata={undefined}
                widgetProps={{
                    id: widgetId,
                    filters,
                    section: "oem-views",
                    calculationMode: calculationMode,
                }}
                menuButtonPosition={props.widgetUid ? "outside" : "inside"}
                marginBottom={70}
                {...props}
            />
            {props.footnote && <span className="pl-3 pb-2 text-left text-xs font-medium">{props.footnote}</span>}
        </div>
    );
};

const TrendChart = ({ id, filters, series, categories, ...props }) => (
    <HighchartsComponent
        widgetProps={{
            id,
            filters,
            section: "oem-views",
        }}
        options={{
            chart: {
                type: "spline",
                height: 500,
                marginTop: 65,
                spacingBottom: 2,
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: true,
                        symbol: "circle",
                    },
                    // dataLabels: {
                    //     enabled: true,
                    // },
                    connectNulls: true,
                    // enableMouseTracking: mouseTracking,
                    cursor: "pointer",
                },
            },
            legend: {
                enabled: true,
                // verticalAlign: 'middle',
                // align: 'right',
                padding: 12,
                itemStyle: {
                    fontSize: "10px",
                    fontWeigth: "600",
                    color: "#595959",
                },
            },
            title: {
                text: props.title,
                align: "center",
                style: {
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#3a0ca3",
                },
            },
            subtitle: {
                text: props.subtitle,
                align: "center",
                style: {
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "#506e96",
                },
            },
            xAxis: {
                categories: categories,
                crosshair: true,
            },
            yAxis: {
                title: {
                    text: null,
                },
            },
            series: series.map((serie) => {
                if (serie.name.length === 3) {
                    return {
                        ...serie,
                        name: serie.name,
                    };
                } else {
                    return serie;
                }
            }),
            lang: {
                noData: "No data available",
            },
            noData: {
                style: {
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "#595959",
                },
            },
        }}
        imgName={"brand-model-trends"}
        {...props}
    />
);

export default TrendBrandAnalysis;
