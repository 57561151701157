import React, { createContext, useState, useCallback } from "react";
import { measureLocalId, newMeasureValueFilter, newPositiveAttributeFilter } from "@gooddata/sdk-model";

import * as Md from "../md/full";
import { useContext } from "react";
import UserContext from "./User";
import { useEffect } from "react";
import { useMemo } from "react";
import { AggregatedBasketViz } from "../components/Baskets/BasketViz/AggregatedBasketViz";
import { BasketViz } from "../components/Baskets/BasketViz/BasketViz";

// Extrapolation
const extrapolations = ["Age/Km Extrap", "No Extrapolation", "Version Extrap"];

// Display mode used in slicing
const displayModes = [
    {
        key: "player",
        title: "Player",
        dataIndex: "player",
        attributes: [Md.CustId],
        isDefault: true,
    },
    {
        key: "versionStd",
        title: "Version",
        //  Used to refer to the database table
        dataIndex: "version_std",
        attributes: [Md.VersionStd],
    },
];

const initialFilters = {
    // SCOPE, MUST BE ONE ONLY

    filterCountryId: newPositiveAttributeFilter(Md.Ctryid, []),
    filterChannel1: newPositiveAttributeFilter(Md.Channel1, []),
    filterDuration: newPositiveAttributeFilter(Md.Duration, []),
    // filterMthCode: newPositiveAttributeFilter(Md.MthCode, [moment().format("YYYY MM")]),
    filterMthCode: newPositiveAttributeFilter(Md.MthCode, []),
    // FILTERS, NONE, ONE OR MANY

    // Parent Filters
    filterMake: newPositiveAttributeFilter(Md.CorrMake, []),
    filterModel: newPositiveAttributeFilter(Md.CorrModel, []),
    filterFuel: newPositiveAttributeFilter(Md.Fuel, []),
    filterTrim: newPositiveAttributeFilter(Md.TrimName, []),
    filterVehSegment: newPositiveAttributeFilter(Md.VehSegment, []),
    filterBody: newPositiveAttributeFilter(Md.BodyStyle, []),
    filterVehType: newPositiveAttributeFilter(Md.VehType, []),
    filterSize: newPositiveAttributeFilter(Md.Size, []),
    filterGearPlus: newPositiveAttributeFilter(Md.GearsPlus, []),
    filterDrive: newPositiveAttributeFilter(Md.Drive, []),
    filterChannel3: newPositiveAttributeFilter(Md.Channel3, []),
    filterCustId: newPositiveAttributeFilter(Md.CustId, []),
    filterCustLabel: newPositiveAttributeFilter(Md.CustLabel, []),
    filterCustmix: newPositiveAttributeFilter(Md.Custmixindex, []),
    filterExtrapType: newPositiveAttributeFilter(Md.Extraptype, []),
    filterRolledForward: newPositiveAttributeFilter(Md.Rolledforward, []),
    filterVersionStd: newPositiveAttributeFilter(Md.VersionStd, []),

    // History, none, 1 or many
    filterMthCodeHistory: newPositiveAttributeFilter(Md.MthCode, []),

    filterListPrice: null,
    filterPs: null,
    filterKw: null,
    filterCo2: null,
    filterPeriodFrequency: null,

    currentFilter: [],
    currentFilterHistory: [],

    currentFilterWithNoPeriodFrequency: [],
    currentFilterHistoryWithNoPeriodFrequency: [],

    // Status
    loading: true,

    // Functions
    updateFilter: null,
    getFilterValue: null,
    getFirstFilterValue: null,
    getScopeForTitle: null,
    getScopeForTitleHistory: null,
    getFiltersForFootnote: null,
    clearFilters: null,
    displayMode: null,
    changeMode: (mode) => {},
    showAsOptions: null,
    showOption: null,
    changeShowOption: (mode) => {},

    // Parameters
    Extrap: [],
    setExtrap: undefined,
};

const FiltersContext = createContext({
    ...initialFilters,
});

const FiltersProvider = ({ children }) => {
    const { loading, defaultUserPref, loadSettings } = useContext(UserContext);

    const [filters, setFilters] = useState({
        ...initialFilters,
        filterCountryId: newPositiveAttributeFilter(Md.Ctryid, [defaultUserPref?.ctryid]),
        filterChannel1: newPositiveAttributeFilter(Md.Channel1, [defaultUserPref?.channel1]),
        filterDuration: newPositiveAttributeFilter(Md.Duration, [defaultUserPref?.duration]),
    });
    const [displayMode, setDisplayMode] = useState(displayModes.find((item) => item.isDefault));

    const [Extrap, setExtrap] = useState(extrapolations);

    const showAsOptions = [
        {
            key: "aggregated",
            title: "Players average",
            component: AggregatedBasketViz,
            disabled: displayMode.key === "player",
        },
        {
            key: "players",
            title: "Players details",
            isDefault: true,
            component: BasketViz,
        },
    ];
    const [showOption, setShowOption] = useState(showAsOptions.find((item) => item.isDefault));
    const {
        filterMthCodeHistory,
        filterCountryId,
        filterMthCode,
        filterChannel1,
        filterChannel3,
        filterExtrapType,
        filterDuration,
        filterMake,
        filterModel,
        filterFuel,
        filterCustId,
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterListPrice,
        filterPs,
        filterKw,
        filterCo2,
        filterCustmix,
        filterRolledForward,
        filterVersionStd,
        filterPeriodFrequency,
    } = filters;

    const updateFilter = useCallback((name, value) => {
        return setFilters((prevState) => ({ ...prevState, [name]: value }));
    }, []);

    const getFilterValue = useCallback((name) => filters[name]?.positiveAttributeFilter?.in?.values || [], [filters]);
    const getFirstFilterValue = useCallback((name) => filters[name]?.positiveAttributeFilter?.in?.values[0] || "", [filters]);
    const getMeasureValue = useCallback(
        (name) =>
            (filters[name]?.measureValueFilter?.condition?.comparison && filters[name]?.measureValueFilter?.condition?.comparison?.operator + " " + filters[name]?.measureValueFilter?.condition?.comparison?.value) ||
            (filters[name]?.measureValueFilter?.condition?.range && filters[name]?.measureValueFilter?.condition?.range?.operator + " " + filters[name]?.measureValueFilter?.condition?.range?.from + " - " + filters[name]?.measureValueFilter?.condition?.range?.to) ||
            undefined,
        [filters],
    );

    const clearFilters = useCallback(() => {
        setFilters({
            ...initialFilters,
            filterCountryId: filters.filterCountryId,
            filterChannel1: filters.filterChannel1,
            filterDuration: filters.filterDuration,
            filterMthCode: filters.filterMthCode,
            filterRolledForward: filters.filterRolledForward,
            loading: false,
        });
    }, [filters]);

    const getFiltersForFootnote = useCallback(() => {
        const make = getFilterValue("filterMake");
        const model = getFilterValue("filterModel");
        const fuel = getFilterValue("filterFuel");
        const trim = getFilterValue("filterTrim");
        const segment = getFilterValue("filterVehSegment");
        const body = getFilterValue("filterBody");
        const pclcv = getFilterValue("filterVehType");
        const size = getFilterValue("filterSize");
        const gears = getFilterValue("filterGearPlus");
        const drive = getFilterValue("filterDrive");
        const product = getFilterValue("filterChannel3");
        const player = getFilterValue("filterCustLabel");
        const quote = getFilterValue("filterExtrapType");
        // const mix = getFilterValue("filterCustmix");
        const listprice = getMeasureValue("filterListPrice");
        const ps = getMeasureValue("filterPs");
        const kw = getMeasureValue("filterKw");
        const co2 = getMeasureValue("filterCo2");
        const filterValues = [
            make.length > 0 && `Make: ${make.join(", ")}`,
            model.length > 0 && `Model: ${model.join(", ")}`,
            fuel.length > 0 && `Energy: ${fuel.join(", ")}`,
            trim.length > 0 && `Trim: ${trim.join(", ")}`,
            segment.length > 0 && `Segment: ${segment.join(", ")}`,
            body.length > 0 && `Body: ${body.join(", ")}`,
            pclcv.length > 0 && `PC/LCV: ${pclcv.join(", ")}`,
            size.length > 0 && `Size: ${size.join(", ")}`,
            gears.length > 0 && `Gears: ${gears.join(", ")}`,
            drive.length > 0 && `Drive: ${drive.join(", ")}`,
            product.length > 0 && `Product: ${product.join(", ")}`,
            player.length > 0 && `Player: ${player.join(", ")}`,
            quote.length > 0 && `Quote: ${quote.join(", ")}`,
            // mix.length > 0 && `Mix: ${mix.join(", ")}`,
            listprice !== undefined && `List Price: ${listprice}`,
            ps !== undefined && `PS: ${ps}`,
            kw !== undefined && `KW: ${kw}`,
            co2 !== undefined && `CO2: ${co2}`,
        ];
        // @ts-ignore
        return filterValues.filter((f) => !f).length !== filterValues.length ? `Filters - ${filterValues.filter((f) => f !== false).join(" | ")}` : undefined;
    }, [getFilterValue]);

    useEffect(() => {
        // when country input updated, reload the settings
        let ctryid = filterCountryId.positiveAttributeFilter.in.values[0];
        let channel1 = filterChannel1.positiveAttributeFilter.in.values[0];
        if (!("ctryid" in defaultUserPref && "channel1" in defaultUserPref) || defaultUserPref.ctryid !== ctryid || defaultUserPref.channel1 !== channel1) {
            loadSettings(ctryid, channel1);
        }
    }, [filterCountryId, filterChannel1]);

    useEffect(() => {
        if (!loading) {
            let obj = {
                loading: false,
            };
            if (defaultUserPref && "ctryid" in defaultUserPref) {
                if (defaultUserPref?.ctryid && defaultUserPref?.channel1 && defaultUserPref?.duration) {
                    obj = {
                        ...obj,
                        filterCountryId: newPositiveAttributeFilter(Md.Ctryid, [defaultUserPref.ctryid]),
                        filterChannel1: newPositiveAttributeFilter(Md.Channel1, [defaultUserPref.channel1]),
                        filterDuration: newPositiveAttributeFilter(Md.Duration, [defaultUserPref.duration]),
                    };
                }
                if (defaultUserPref?.fl_rw === 0) {
                    obj = {
                        ...obj,
                        filterRolledForward: newPositiveAttributeFilter(Md.Rolledforward, ["No"]),
                    };
                } else if (filterRolledForward.positiveAttributeFilter.in.values?.length > 0) {
                    obj = {
                        ...obj,
                        filterRolledForward: newPositiveAttributeFilter(Md.Rolledforward, []),
                    };
                }
                obj = {
                    ...obj,
                    filterPeriodFrequency: Boolean(defaultUserPref?.fl_weekly) ? newPositiveAttributeFilter(Md.Latestwchar, ["1"]) : newPositiveAttributeFilter(Md.Latestmchar, ["1"]),
                };

                setFilters((prevState) => ({
                    ...prevState,
                    ...obj,
                }));
            }
        }
    }, [loading, defaultUserPref]);

    const value = useMemo(() => {
        const genericFilters = [
            filterCountryId,
            filterChannel1,
            filterChannel3,
            filterExtrapType,
            filterDuration,
            filterMake,
            filterModel,
            filterFuel,
            filterCustId,
            filterCustLabel,
            filterVehType,
            filterVehSegment,
            filterBody,
            filterTrim,
            filterDrive,
            filterGearPlus,
            filterSize,
            filterListPrice,
            filterPs,
            filterKw,
            filterCo2,
            filterCustmix,
            filterRolledForward,
            filterVersionStd,
        ];

        const currentFilterWithNoPeriodFrequency = [filterMthCode, ...genericFilters];

        const currentFilterHistoryWithNoPeriodFrequency = [filterMthCodeHistory, ...genericFilters];

        const currentFilter = [...currentFilterWithNoPeriodFrequency, filterPeriodFrequency];

        const currentFilterHistory = [...currentFilterHistoryWithNoPeriodFrequency, filterPeriodFrequency];

        return { currentFilter, currentFilterHistory, currentFilterWithNoPeriodFrequency, currentFilterHistoryWithNoPeriodFrequency };
    }, [
        filterMthCode,
        filterMthCodeHistory,
        filterCountryId,
        filterChannel1,
        filterChannel3,
        filterExtrapType,
        filterDuration,
        filterMake,
        filterModel,
        filterFuel,
        filterCustId,
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterListPrice,
        filterPs,
        filterKw,
        filterCo2,
        filterCustmix,
        filterRolledForward,
        filterVersionStd,
        filterPeriodFrequency,
    ]);

    return (
        <FiltersContext.Provider
            value={{
                ...filters,
                ...value,
                updateFilter,
                getFilterValue,
                getFirstFilterValue,
                getFiltersForFootnote,
                clearFilters,
                displayMode,
                changeMode: setDisplayMode,
                showOption,
                changeShowOption: setShowOption,
                showAsOptions,
                Extrap,
                setExtrap,
            }}
        >
            {children}
        </FiltersContext.Provider>
    );
};

export default FiltersContext;

export { FiltersProvider, displayModes };
