import { saveAs } from "file-saver";

const TIMEOUT_DURATION = 600000; // 10 minutes

export const generatePdf = async ({ data, title, subtitle, onProgress }) => {
    //@ts-ignore
    const worker = new Worker(new URL("../workers/pdfWorker.js", import.meta.url), {
        type: "module",
    });

    let progressTimer = null;
    let lastProgress = 0;

    try {
        const workerPromise = new Promise((resolve, reject) => {
            worker.onmessage = (e) => {
                if (e.data.error) {
                    reject(new Error(e.data.error));
                } else if (e.data.progress) {
                    lastProgress = e.data.progress;
                    onProgress(e.data.progress);
                } else if (e.data.blob) {
                    resolve(e.data.blob);
                }
            };

            worker.onerror = (error) => {
                reject(new Error(`Worker error: ${error.message}`));
            };

            // Add intermediate progress updates during long operations
            progressTimer = setInterval(() => {
                if (lastProgress >= 85 && lastProgress < 95) {
                    onProgress(lastProgress + 0.1);
                }
            }, 500);

            worker.postMessage({
                data,
                title,
                subtitle,
                chunkSize: 28,
            });
        });

        // Add timeout handling
        const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => {
                reject(new Error("PDF generation timed out. Please try with fewer records."));
            }, TIMEOUT_DURATION);
        });

        const blob = await Promise.race([workerPromise, timeoutPromise]);

        // Save the PDF
        saveAs(blob, `${title}.pdf`);
        return true;
    } catch (error) {
        console.error("PDF Generation Error:", error);
        throw error;
    } finally {
        clearInterval(progressTimer);
        worker.terminate();
    }
};
