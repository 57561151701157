import React, { useContext, useMemo } from "react";
import { ErrorComponent, LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";

import HighchartsComponent from "../HighchartsComponent";
import Highcharts from "highcharts";
import { useAppContext } from "../../contexts/AppContext";
import MeasuresContext from "../../contexts/Measures";
import UserContext from "../../contexts/User";
import { customFieldColors, operations } from "./CarSelectorConfigModal";
import { processCustomerData } from "../../utils/calculationUtils";

import FiltersContext from "../../contexts/Filters";
import moment from "moment";
import { attributeLocalId, idRef, measureLocalId, newAbsoluteDateFilter } from "@gooddata/sdk-model";
import * as Md from "../../md/full";

// allMeasures is an object that contains all the measures and their respective index

function LineVehicles2({ seriesBy, slicesBy, filters, ...props }) {
    const id = "vehicle-trends-chart";
    const { getColorByCustId, getCustlabelByCustId } = useAppContext();
    const { selectedKpi, selectedAdjDeposit } = useContext(MeasuresContext);
    const { kpiSettings } = useContext(UserContext);
    const { result, status } = useExecutionDataView({ execution: { seriesBy, slicesBy, filters } });
    const series = result ? result.data().series().toArray() : [];
    const slices = result ? result.data().slices().toArray() : [];

    const settingsAliases = {
        flag_ser: "Maintenance",
        flag_bre: "Breakdown",
        flag_ins: "Insurance",
        flag_rep: "Replacement",
        flag_fin: "Financial loss",
        flag_co2: "CO2 Malus",
        flag_tyr: "Tyres",
        flag_win: "Winter Tyres",
        flag_evb: "EV Bonus",
    };

    const services = Object.keys(kpiSettings)
        .filter((key) => kpiSettings[key] === true)
        .map((key) => settingsAliases[key]);

    const valableCustIds =
        series &&
        series
            .map((s) => {
                return {
                    custId: s.scopeTitles()[0],
                    data: s.dataPoints(),
                };
            })
            .reduce((acc, val, index) => {
                const groupIndex = Math.floor(index / (seriesBy.length - 1));
                if (!acc[groupIndex]) {
                    acc[groupIndex] = [];
                }
                acc[groupIndex].push(val);
                return acc;
            }, [])
            .filter((f) => f[2].data.filter((m) => m.rawValue !== null).length > 0)
            .map((m) => m[0].custId);
    const months = slices?.map((slice) => slice.sliceTitles()[0]);
    const preline =
        selectedAdjDeposit !== "Total Payable by month"
            ? valableCustIds.length > 0 &&
              series &&
              series
                  .filter((f, i) => i % (seriesBy.length - 1) === selectedKpi?.index) // 27 is max measures, modulo needs to find series
                  .filter((f) => valableCustIds.includes(f.scopeTitles()[0]))
                  ?.map((value) => {
                      return {
                          custId: value.scopeTitles()[0],
                          name: getCustlabelByCustId(value.scopeTitles()[0]),
                          data: value.dataPoints().map((p) => (Number(p.rawValue) > 1 ? Math.round(Number(p.rawValue)) : Math.round(10000 * Number(p.rawValue)) / 100)),
                          color: getColorByCustId(value.scopeTitles()[0]),
                      };
                  })
            : series &&
              series
                  .filter((f, i) => i % (seriesBy.length - 1) === selectedKpi?.index) // 27 is max measures, modulo needs to find series
                  ?.map((value) => {
                      return {
                          custId: value.scopeTitles()[0],
                          name: getCustlabelByCustId(value.scopeTitles()[0]),
                          data: value.dataPoints().map((p) => (Number(p.rawValue) > 1 ? Math.round(Number(p.rawValue)) : Math.round(10000 * Number(p.rawValue)) / 100)),
                          color: getColorByCustId(value.scopeTitles()[0]),
                      };
                  });
    const lines =
        preline &&
        preline.reduce((a, b) => {
            if (!a[b.name]) {
                a[b.name] = { name: b.name, data: b.data.map((m) => (m === 0 ? null : m)), color: b.color };
            } else {
                a[b.name]["data"] = a[b.name]["data"].map((a, i) => {
                    return a + b["data"][i] === 0 ? null : a + b["data"][i];
                });
            }
            return a;
        }, []);
    // Transfom custom fields to data point lines
    const customFieldLines =
        props.customFields?.map((field, i) => {
            const playerLines = preline.filter((line) => field.players.includes(line.custId));
            const data = months.map((month, index) =>
                operations.calculate(
                    playerLines.map((pline) => (pline.data[index] > 0 ? pline.data[index] : null)),
                    field.operation,
                    selectedKpi.roundValue,
                ),
            );
            return Object.assign({
                name: field.fieldName,
                data: data,
                color: customFieldColors[i % customFieldColors.length],
                dashStyle: "Dash",
            });
        }) || [];

    const data = slices?.map((slice) => ({
        label: slice.sliceTitles()[0],
        ...slice.dataPoints().map((p) => Number(p.rawValue)),
    }));

    const diff = series.map((s, index) => {
        return {
            custId: getCustlabelByCustId(s.scopeTitles()[0]),
            data: s.dataPoints().map((p, i) => {
                return {
                    month: slices[i].sliceTitles()[0],
                    value: p.rawValue,
                };
            }),
        };
    });

    const diffrenceData = processCustomerData(diff);
    return (
        <>
            {status === "loading" && (
                <div className="my-2">
                    <LoadingComponent />
                </div>
            )}

            {status === "error" && <ErrorComponent message="Unable to calculate your query" description={"Please verify if the selected vehicles are compatible with the active filters"} />}

            {status === "success" && (
                <>
                    <HighchartsComponent
                        widgetProps={{
                            id,
                            filters,
                            section: "car-selector",
                            flag_services: props?.kpiSettings,
                        }}
                        options={{
                            chart: {
                                type: "spline",
                                height: props.height || 400,
                            },
                            title: {
                                text: "",
                                style: {
                                    display: "none",
                                },
                            },
                            subtitle: {
                                text: "",
                                style: {
                                    display: "none",
                                },
                            },
                            xAxis: {
                                categories: data.map((m) => m["label"]),
                                crosshair: true,
                            },
                            yAxis: {
                                title: {
                                    text: "",
                                },
                            },
                            tooltip: {
                                crosshairs: true,
                                valueDecimals: 2,
                                useHTML: true,
                                backgroundColor: "rgba(255, 255, 255, 0.9)",
                                shadow: true,
                                style: {
                                    fontSize: "12px",
                                    color: "#333",
                                },
                                formatter: function () {
                                    let s = '<table style="border-collapse: collapse; width: 100%;">';
                                    s += `<tr><th colspan="2" padding-bottom: 5px;"><b>${this.x}</b></th></tr>`;
                                    s += `<tr>
                                            <td style="text-align: left; padding: 2px;"><b>${this.series.name}:</b></td>
                                            <td style="text-align: right; padding: 2px;">${this.y}</td>
                                          </tr>`;

                                    const custId = this.series.name;
                                    if (diffrenceData[custId]) {
                                        Object.entries(diffrenceData[custId]).forEach(([measure, changes]) => {
                                            const change = changes.find((c) => c.month == this.x);
                                            if (change && change.value !== 0 && [...services, "List Price", "Deposit", "Web Price"].includes(measure)) {
                                                s += `<tr>
                                                        <td style="text-align: left; padding: 2px;">${measure}:</td>
                                                        <td style="text-align: right; padding: 2px;">${change.value > 0 ? "+" + change.value : change.value}</td>
                                                      </tr>`;
                                            }
                                        });
                                    }
                                    s += "</table>";
                                    return s;
                                },
                            },
                            plotOptions: {
                                column: {
                                    borderWidth: 0,
                                    pointPadding: 0.1,
                                    groupPadding: 0,
                                    pointWidth: 55,
                                },
                                series: {
                                    dataLabels: {
                                        enabled: true,
                                        // formatter: function () {
                                        //     return Highcharts.numberFormat(this.y, 0);
                                        // },
                                    },
                                },
                            },
                            series: [...Object.values(lines), ...customFieldLines],
                        }}
                        imgName="car-selector-trends"
                        {...props}
                    />
                </>
            )}
        </>
    );
}

export default function LineVehicles({ seriesBy, slicesBy, filters, ...props }) {
    const id = "vehicle-trends-chart";
    const { kpiSettings } = useContext(UserContext);
    const settingsAliases = {
        flag_ser: "Maintenance",
        flag_bre: "Breakdown",
        flag_ins: "Insurance",
        flag_rep: "Replacement",
        flag_fin: "Financial loss",
        flag_co2: "CO2 Malus",
        flag_tyr: "Tyres",
        flag_win: "Winter Tyres",
        flag_evb: "EV Bonus",
    };

    const services = Object.keys(kpiSettings)
        .filter((key) => kpiSettings[key] === true)
        .map((key) => settingsAliases[key]);
    const { getFirstFilterValue } = useContext(FiltersContext);
    const { selectedKpi, selectedAdjDeposit } = useContext(MeasuresContext);
    const { dateRange } = useAppContext();
    const { getColorByCustId, getCustlabelByCustId } = useAppContext();

    const formattedDateRange = dateRange.map((dt) => dt.format("YYYY-MM-DD"));
    const queryFilters = [
        ...filters,
        formattedDateRange.length === 2 ? newAbsoluteDateFilter(Md.DateDatasets.DateOfScraping.ref, formattedDateRange[0], formattedDateRange[1]) : newAbsoluteDateFilter(Md.DateDatasets.DateOfScraping.ref, moment().subtract({ years: 3 }).format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")),
    ];

    const { result, status } = useExecutionDataView({ execution: { seriesBy: [...seriesBy], slicesBy, filters: queryFilters, sortBy: props.sortBy } });

    const slices = result ? result.data().slices().toArray() : [];
    const series = result ? result.data().series().toArray() : [];
    const dates = slices.map((slice) => {
        return slice.sliceTitles()[0];
    });
    const datasets = series
        .filter((serie, index) => index % (seriesBy.length - 1) === selectedKpi.index)
        .map((serie) => {
            const custId = serie.scopeTitles()[0];
            return {
                custId: custId,
                name: getCustlabelByCustId(custId),
                // start with a null value to create a padding on the trend line
                // data: [null, ...serie.dataPoints().map((dp) => (dp.rawValue !== null ? selectedKpi.roundValue(dp.rawValue) : null))],
                data: serie.dataPoints().map((dp) => {
                    const kpiValue = dp.rawValue !== null ? selectedKpi.roundValue(dp.rawValue) : null;
                    return [new Date(dp.sliceDesc.sliceTitles()[0]).getTime(), kpiValue];
                }),
                color: getColorByCustId(custId),
            };
        });

    const maxElem = datasets.length > 0 ? datasets.reduce((acc, obj) => [...acc, ...obj.data.map((dp) => dp[1])], []).sort((a, b) => b - a)[0] : null;
    const maxValue = maxElem ? (selectedKpi.index !== 1 ? maxElem * 1.1 : maxElem + 100) : null;

    const diff = series.map((s, index) => {
        return {
            custId: getCustlabelByCustId(s.scopeTitles()[0]),
            data: s.dataPoints().map((p, i) => {
                return {
                    scrapingDate: slices[i].sliceTitles()[0],
                    value: p.rawValue,
                };
            }),
        };
    });

    const differenceData = processCustomerData(diff, "scrapingDate");

    const options = {
        chart: {
            type: "spline",
            zoomType: "xy",
            height: props.height || 550,
            panning: {
                enabled: true,
                type: "xy",
            },
            panKey: "shift",
            // Enable animation
            animation: {
                duration: 1000,
            },
        },
        title: {
            text: null,
        },
        xAxis: {
            type: "datetime",
            labels: {
                rotation: -45,
                formatter: function () {
                    return Highcharts.dateFormat("%e %b %Y", this.value);
                },
            },
            title: {
                text: null,
            },
        },

        yAxis: {
            title: {
                text: null,
            },
            minPadding: 0.05,
            maxPadding: 0.05,
            startOnTick: false,
            endOnTick: false,
            max: maxValue,
            events: {
                // afterSetExtremes: function (e) {
                //     if (e.dataMin !== undefined && e.dataMax !== undefined) {
                //         const padding = (e.dataMax - e.dataMin) * 0.1;
                //         this.setExtremes(e.dataMin - padding, e.dataMax + padding);
                //     }
                // },
            },
        },

        plotOptions: {
            series: {
                marker: {
                    enabled: true,
                    radius: 3,
                },
                lineWidth: 3,
                connectNulls: true,
                dataLabels: {
                    enabled: true,
                    formatter() {
                        return '<span style="color: ' + this.color + '">' + this.y + "</span>";
                    },
                },
            },
        },

        tooltip: {
            // shared: true,
            crosshairs: true,
            useHTML: true,
            formatter: function () {
                let s = '<table style="border-collapse: collapse; width: 100%;">';
                s += `<tr><th colspan="2" padding-bottom: 5px;"><b>${Highcharts.dateFormat("%e %b %Y", this.x)}</b></th></tr>`;
                s += `<tr>
                        <td style="text-align: left; padding: 2px;"><b>${this.series.name}:</b></td>
                        <td style="text-align: right; padding: 2px;">${this.y}</td>
                      </tr>`;

                const custId = this.series.name;
                if (differenceData[custId]) {
                    Object.entries(differenceData[custId]).forEach(([measure, changes]) => {
                        const change = changes.find((c) => c.scrapingDate == moment(this.x).format("YYYY-MM-DD"));
                        if (change && change.value !== 0 && [...services, "List Price", "Deposit", "Web Price"].includes(measure)) {
                            s += `<tr>
                                    <td style="text-align: left; padding: 2px;">${measure}:</td>
                                    <td style="text-align: right; padding: 2px;">${change.value > 0 ? "+" + change.value : change.value}</td>
                                  </tr>`;
                        }
                    });
                }
                s += "</table>";
                return s;
            },
        },

        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            enabled: false,
                        },
                        yAxis: {
                            labels: {
                                align: "left",
                                x: 0,
                                y: -5,
                            },
                        },
                    },
                },
            ],
        },

        series: datasets,
    };

    return status === "loading" && dates.length === 0 ? (
        <div
            style={{
                height: "100px",
            }}
        >
            <LoadingComponent />
        </div>
    ) : (
        <HighchartsComponent
            widgetProps={{
                id,
                filters,
            }}
            options={options}
            {...props}
        />
    );
}
