import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { newMeasureSort } from "@gooddata/sdk-model";
import * as Md from "../md/full";
// import ExportTable from "../components/Export/ExportTable";
import ExportTable from "../components/Export/ExportTable";
import FiltersContext from "../contexts/Filters";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import MeasuresContext from "../contexts/Measures";
import { utils, writeFileXLSX, writeFile } from "xlsx";
// import { useAuth } from "../contexts/Auth";
import UserContext from "../contexts/User";
import { useAppContext } from "../contexts/AppContext";
import { calculateAdjustment, toPivot } from "../utils/calculationUtils";
import { useExecutionDataView } from "@gooddata/sdk-ui";
import { Modal, Typography, Button } from "antd";
import { useBasketContext } from "../contexts/BasketProvider";
import { useQuery } from "@apollo/client";
import ExportFullCatalog from "../components/Export/ExportFullCatalog";
import LoadingSpinner from "../components/LoadingSpinner";
import { IncrementalExport, IncrementalExportFullCatalog } from "../components/Export/IncrementalExport";
import { ENABLED_SETTINGS } from "../queries/Users";
import moment from "moment";

const settingsAliases = {
    flag_ser: "Maintenance",
    flag_bre: "Breakdown",
    flag_ins: "Insurance",
    flag_rep: "Replacement",
    flag_fin: "Financial loss",
    flag_co2: "CO2 Malus",
    flag_tyr: "Tyres",
    flag_win: "Winter Tyres",
    flag_evb: "EV Bonus",
};

function Export() {
    const [exportMode, setExportMode] = useState(undefined);
    const { currentFilter, filterCountryId, filterChannel1, getFirstFilterValue, getFiltersForFootnote } = useContext(FiltersContext);
    const { seriesBy, selectedAdjDeposit } = useContext(MeasuresContext);
    const { kpiSettings, IncServices } = useContext(UserContext);
    const { getCustlabelByCustId, getCountryByCode, tabindex } = useAppContext();
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [corrMakes, setCorrMakes] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmedExportMode, setConfirmedExportMode] = useState(undefined);
    // Ready | validated
    const [incrementalLoadStatus, setIncrementalLoad] = useState(undefined);
    const { userBaskets, systemBaskets, selectedBasketFilters } = useBasketContext();
    const transformedUserBaskets =
        selectedBasketFilters !== undefined
            ? userBaskets
                  .filter((basket) => selectedBasketFilters?.includes(basket))
                  .map((basket) => {
                      return {
                          name: basket.name,
                          vehicles: basket.basketFilters.map((filter) => {
                              return filter.value;
                          }),
                      };
                  })
            : userBaskets.map((basket) => {
                  return {
                      name: basket.name,
                      vehicles: basket.basketFilters.map((filter) => {
                          return filter.value;
                      }),
                  };
              });
    const transformedSystemBaskets =
        selectedBasketFilters !== undefined
            ? systemBaskets
                  .filter((basket) => selectedBasketFilters?.includes(basket))
                  .map((basket) => {
                      return {
                          name: basket.name,
                          vehicles: basket.basketFilters.map((filter) => {
                              return filter.value;
                          }),
                      };
                  })
            : systemBaskets.map((basket) => {
                  return {
                      name: basket.name,
                      vehicles: basket.basketFilters.map((filter) => {
                          return filter.value;
                      }),
                  };
              });
    const basketList = [...transformedUserBaskets, ...transformedSystemBaskets];

    const enabledKpiSettings = useQuery(ENABLED_SETTINGS, {
        variables: { ctryid: filterCountryId.positiveAttributeFilter.in["values"][0], channel1: filterChannel1.positiveAttributeFilter.in["values"][0] },
    }).data?.re_rentaleye_re_services_settings[0];

    const serviceMessage = useMemo(() => {
        const services =
            kpiSettings && enabledKpiSettings
                ? Object.entries(kpiSettings)
                      .filter(([key, val]) => val === 1 && key in enabledKpiSettings && enabledKpiSettings[key] === 1)
                      .reduce((acc, [key, val]) => {
                          acc.push(settingsAliases[key]);
                          return acc;
                      }, [])
                : [];
        const message = IncServices ? (services?.length > 0 ? `${services.join(", ")}` : "No Services") : "No services adjustment (services included as per web offer)";
        return message;
    }, [kpiSettings, enabledKpiSettings, IncServices]);

    const transformQuoteData = useCallback(
        ({ slices, series, baskets, kpiSettings, selectedAdj, serviceMessage }) => {
            let slicedata = slices;
            if (selectedAdj !== "Total Payable by month") {
                slicedata = slices?.filter((slice) => slice.dataPoints()[2].rawValue !== null);
            }
            return slicedata
                ?.map((slice, index) => {
                    const vehicleBaskets = baskets
                        .filter((basket) => basket.vehicles.includes(slice.sliceTitles()[0]))
                        .map((basket) => {
                            return basket.name;
                        })
                        ?.filter((item, index, self) => {
                            return index === self.indexOf(item);
                        })
                        ?.join(", ");
                    const monthSplit = slice.sliceTitles()[13].split(" ");
                    const kpiValues = slice.dataPoints();
                    const composer = {
                        flag_ser: kpiSettings["flag_ser"],
                        flag_tyr: kpiSettings["flag_tyr"],
                        flag_bre: kpiSettings["flag_bre"],
                        flag_rep: kpiSettings["flag_rep"],
                        flag_ins: kpiSettings["flag_ins"],
                        flag_fin: kpiSettings["flag_fin"],
                        flag_win: kpiSettings["flag_win"],
                        flag_co2: kpiSettings["flag_co2"],
                        flag_evb: kpiSettings["flag_evb"],
                    };

                    const services = {
                        Maintenance: Number(slice.dataPoints()[5].rawValue),
                        Tyres: Number(slice.dataPoints()[7].rawValue),
                        Breakdown: Number(slice.dataPoints()[9].rawValue),
                        Replacement: Number(slice.dataPoints()[11].rawValue),
                        Insurance: Number(slice.dataPoints()[13].rawValue),
                        "Financial Loss": Number(slice.dataPoints()[15].rawValue),
                        "Winter Tyres": Number(slice.dataPoints()[17].rawValue),
                        "EV Bonus": Number(slice.dataPoints()[19].rawValue),
                        "Co2 Malus": Number(slice.dataPoints()[21].rawValue),
                    };
                    return {
                        Vehicle: slice.sliceTitles()[0],
                        Make: slice.sliceTitles()[1],
                        Model: slice.sliceTitles()[2],
                        Vehicle_Segment: slice.sliceTitles()[3],
                        Body_Type: slice.sliceTitles()[4],
                        Trim: slice.sliceTitles()[5],
                        Gearbox: slice.sliceTitles()[6],
                        Doors: kpiValues[36].rawValue === "0" ? 0 : Number(kpiValues[36].rawValue) > 0 ? Number(kpiValues[36].rawValue) : null,
                        Power: kpiValues[22].rawValue === "0" ? 0 : Number(kpiValues[22].rawValue) > 0 ? Number(kpiValues[22].rawValue) : null,
                        Energy: slice.sliceTitles()[7],
                        Version: slice.sliceTitles()[17],
                        Channel: slice.sliceTitles()[21],
                        Finance_Type: slice.sliceTitles()[20],
                        CO2: kpiValues[37].rawValue === "0" ? 0 : Number(kpiValues[37].rawValue) > 0 ? Number(kpiValues[37].rawValue) : null,
                        List_Price: kpiValues[1].rawValue === "0" ? 0 : Number(kpiValues[1].rawValue) > 0 ? Number(Number(kpiValues[1].rawValue).toFixed(0)) : null,
                        Age_Mileage: slice.sliceTitles()[8],
                        Month: `01/${monthSplit[1]}/${monthSplit[0]}`,
                        Capture_date: moment(slice.sliceTitles()[24]).format("DD/MM/YYYY"),
                        Rental_Offer: slice.sliceTitles()[14],
                        Web_Rental: kpiValues[3].rawValue === "0" ? 0 : Number(kpiValues[3].rawValue) > 0 ? Number((Math.round(Number(kpiValues[3].rawValue) * 100) / 100).toFixed(0)) : null,
                        Web_Rental_Includes: Object.entries(services)
                            .filter((m) => m[1] === 1)
                            .map((m) => m[0])
                            .join(" , "),
                        Web_Deposit: Number(Number(kpiValues[38].rawValue).toFixed(0)),
                        Target_Deposit: selectedAdj !== "Total Payable by month" ? Number(selectedAdj) : "Total Payable",
                        Adj_Deposit: Number(Number(kpiValues[2].rawValue).toFixed(0)),
                        // Calculated with flags
                        Maintenance: calculateAdjustment(slice, 4, composer["flag_ser"], IncServices),
                        Tyres: calculateAdjustment(slice, 6, composer["flag_tyr"], IncServices),
                        Breakdown: calculateAdjustment(slice, 8, composer["flag_bre"], IncServices),
                        Replacement: calculateAdjustment(slice, 10, composer["flag_rep"], IncServices),
                        Insurance: calculateAdjustment(slice, 12, composer["flag_ins"], IncServices),
                        Financial_Loss: calculateAdjustment(slice, 14, composer["flag_fin"], IncServices),
                        Winter_Tyres: calculateAdjustment(slice, 16, composer["flag_win"], IncServices),
                        EV_Bonus: calculateAdjustment(slice, 18, composer["flag_evb"], IncServices),
                        Co2_Malus: calculateAdjustment(slice, 20, composer["flag_co2"], IncServices),
                        Adj_Rental: kpiValues[23].rawValue === "0" ? 0 : Number(kpiValues[23].rawValue) > 0 ? Number((Math.round(Number(kpiValues[23].rawValue) * 100) / 100).toFixed(0)) : null,
                        Adj_Rental_Includes: serviceMessage,
                        Leasing_Factor: kpiValues[1].rawValue === "0" ? 0 : Number(kpiValues[1].rawValue) > 0 ? Number((kpiValues[24].rawValue * 100).toFixed(2)) : null,
                        Player: getCustlabelByCustId(slice.sliceTitles()[19]),
                        Player_Code: slice.sliceTitles()[19],
                        Country: getCountryByCode(slice.sliceTitles()[9]),
                        Comment: slice.sliceTitles()[15],
                        Discount: kpiValues[26].rawValue === "0" ? 0 : Number(kpiValues[26].rawValue) > 0 ? Number(kpiValues[26].rawValue) : null,
                        Yearly_Mileage: kpiValues[34].rawValue === "0" ? 0 : Number(kpiValues[34].rawValue) > 0 ? Number(kpiValues[34].rawValue) : null,
                        KW: kpiValues[29].rawValue === "0" ? 0 : Number(kpiValues[29].rawValue) > 0 ? Number(kpiValues[29].rawValue) : null,
                        Months: kpiValues[35].rawValue === "0" ? 0 : Number(kpiValues[35].rawValue) > 0 ? Number(kpiValues[35].rawValue) : null,
                        Pack: slice.sliceTitles()[10],
                        RV: kpiValues[25].rawValue === "0" ? 0 : Number(kpiValues[25].rawValue) > 0 ? Math.round(Number(kpiValues[25].rawValue)) : null,
                        Size: slice.sliceTitles()[11],
                        Wheelbase: slice.sliceTitles()[12],
                        Roll_Forward: slice.sliceTitles()[16],
                        Quote_Type: Number(kpiValues[33].rawValue) === 0 ? "Age/Km Extrap" : Number(kpiValues[33].rawValue) === 1 ? "Actual Quote" : Number(kpiValues[33].rawValue) === 2 ? "Version Extrap" : null,
                        Id: slice.sliceTitles()[18],
                        Baskets: vehicleBaskets ? vehicleBaskets : null,
                        Customer_code: slice.sliceTitles()[22],
                        Url: slice.sliceTitles()[23],
                        OTR_Price: kpiValues[39].rawValue === "0" ? 0 : Number(kpiValues[39].rawValue) > 0 ? Number(kpiValues[39].rawValue) : null,
                        Interest_Rate: kpiValues[40].rawValue === "0" ? 0 + "%" : Number(kpiValues[40].rawValue) > 0 ? `${(Number(kpiValues[40].rawValue) * 100).toFixed(3)}%` : null,
                        Finance_Contribution: kpiValues[41].rawValue === "0" ? 0 : Number(kpiValues[41].rawValue) > 0 ? Number(Number(kpiValues[41].rawValue).toFixed(0)) : null,
                        Total_Charges: kpiValues[42].rawValue === "0" ? 0 : Number(kpiValues[42].rawValue) > 0 ? Number(Number(kpiValues[42].rawValue).toFixed(0)) : null,
                        Total_Payable: kpiValues[43].rawValue === "0" ? 0 : Number(kpiValues[43].rawValue) > 0 ? Number(Number(kpiValues[43].rawValue).toFixed(0)) : null,
                    };
                })
                .sort((a, b) => a.Vehicle.localeCompare(b.Vehicle));
        },
        [basketList, selectedAdjDeposit, kpiSettings, serviceMessage],
    );

    const transformVehicleData = useCallback(
        ({ slices, series, baskets, selectedAdj, kpiSettings = {} }) => {
            return toPivot({
                dataTable: transformQuoteData({ series, slices, baskets, selectedAdj, kpiSettings, serviceMessage }),
                categoryLabel: "Vehicle",
                valueLabel: "Adj_Rental",
                comparatorLabel: "Player",
                additionalColumns: ["Make", "Model", "Vehicle_Segment", "Body_Type", "Channel", "Finance_Type", "Trim", "Gearbox", "Doors", "Power", "Energy", "Age_Mileage", "Country", "Pack", "Wheelbase", "Baskets", "Target_Deposit", "Web_Rental_Includes", "Adj_Rental_Includes"],
            });
        },
        [serviceMessage, transformQuoteData],
    );

    const generateTableParams = (intialData) => {
        if (intialData?.data) {
            setData(intialData.data);
            setColumns(
                intialData.columns
                    .filter((m) => m !== "Url")
                    .map((m) => {
                        return {
                            title: () => <div>{m}</div>,
                            dataIndex: m,
                            key: m,
                            align: m !== "Vehicle" && "center",
                            fixed: m === "Vehicle" ? "left" : undefined,
                            width: m === "Vehicle" ? 400 : 150,
                            ellipsis: ["Web_Rental_Includes", "Adj_Rental_Includes", "Finance_Contribution", "Baskets", "Version", "Comment"].includes(m) ? true : false,
                            render: (cell) => {
                                return <Typography.Text style={{ fontSize: 11 }}>{cell}</Typography.Text>;
                            },
                        };
                    }),
            );
        } else if (intialData?.length > 0) {
            setData(intialData);
            setColumns(
                Object.keys(intialData[0])
                    .filter((m) => m !== "Url")
                    .map((m) => {
                        return {
                            title: () => <div>{m}</div>,
                            dataIndex: m,
                            key: m,
                            fixed: m === "Vehicle" ? "left" : undefined,
                            align: m !== "Vehicle" && "center",
                            ellipsis: ["Web_Rental_Includes", "Adj_Rental_Includes", "Finance_Contribution", "Baskets", "Version", "Comment"].includes(m) ? true : false,
                            width: m === "Vehicle" ? 400 : m.length >= 15 ? 150 : ["KW", "RV", "Size", "CO2"].includes(m) ? 100 : 130,
                            render: (cell) => {
                                return <Typography.Text style={{ fontSize: 11 }}>{cell}</Typography.Text>;
                            },
                        };
                    }),
            );
        }
    };

    const tabs = [
        {
            label: "By quote",
            transformHandler: transformQuoteData,
        },
        {
            label: "By Vehicle",
            transformHandler: transformVehicleData,
        },
    ];

    // Service Settings included in adj Rental
    const vehicleTableAttributes = [Md.VersionStd, Md.CorrMake, Md.CorrModel, Md.VehSegment, Md.BodyStyle, Md.TrimName, Md.GearsPlus, Md.Fuel, Md.Duration, Md.Ctryid, Md.Pack, Md.Size, Md.Wheelbase, Md.MthCode, Md.RentalOffer, Md.Warning, Md.Rolledforward];
    const measures = [...seriesBy, Md.ActualFlag.Avg, Md.AnnualContractDistance.Avg, Md.ContractMths.Avg, Md.Doors.Avg, Md.Emissions.Avg, Md.Deposit.Avg, Md.OtrPrice.Avg, Md.Apr.Avg, Md.FinanceContrib.Avg, Md.TotalCharges.Avg, Md.TotalPayable.Avg];
    const execDefinition = {
        seriesBy: measures,
        slicesBy: [...vehicleTableAttributes, Md.Version, Md.IdRecord, Md.CustId, Md.Channel3, Md.Channel1, Md.CustCode, Md.Url, Md.DateDatasets.DateOfScraping.Date.YyyyMmDd],
        filters: currentFilter,
    };

    const fileName = `Rentaleye-export_${getFirstFilterValue("filterCountryId")}-${getFirstFilterValue("filterChannel1")}-${getFirstFilterValue("filterDuration")}`;
    const exportModes = [
        {
            id: "excel",
            title: "Export by Excel",
            download: (data, type) => {
                const ws = utils.json_to_sheet(data);
                const wb = utils.book_new();
                utils.book_append_sheet(wb, ws, "Data");
                return new Promise((resolve, reject) => {
                    resolve(writeFileXLSX(wb, `${fileName}-${type}.xlsx`));
                });
            },
        },
        {
            id: "csv",
            title: "Export by CSV",
            download: (data, type) => {
                const ws = utils.json_to_sheet(data);
                const wb = utils.book_new();
                utils.book_append_sheet(wb, ws, "Data");
                return new Promise((resolve, reject) => {
                    resolve(writeFile(wb, `${fileName}-${type}.csv`));
                });
            },
        },
    ];

    const { result, status } = useExecutionDataView({
        execution: execDefinition,
        window: {
            offset: [0],
            size: [100, 100],
        },
    });

    // Getting the list of all makes and sort them by number of offers used in incremental load if needed
    const { result: resultMakes } = useExecutionDataView({
        execution: {
            seriesBy: [Md.VersionStdCount],
            slicesBy: [Md.CorrMake],
            sortBy: [newMeasureSort(Md.VersionStdCount, "desc")],
        },
    });

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        const series = result?.data().series().toArray();
        // if (basketList?.length > 0) {
        const transformedData = tabs[tabindex]?.transformHandler({ slices, series, baskets: basketList, selectedAdj: selectedAdjDeposit, kpiSettings: kpiSettings, serviceMessage });
        generateTableParams(transformedData);
        return () => {
            setData([]);
            setExportMode(undefined);
        };
    }, [result, tabindex, selectedAdjDeposit, kpiSettings, serviceMessage]);

    useEffect(() => {
        const slices = resultMakes?.data().slices().toArray();
        const preData = slices?.map((slice) =>
            Object.assign({
                title: slice.sliceTitles()[0],
                versionCount: slice.rawData()[0],
            }),
        );
        preData && setCorrMakes(preData);
    }, [resultMakes]);

    useEffect(() => {
        setIncrementalLoad(status === "error" && corrMakes.length > 0 ? "ready" : undefined);
    }, [status, corrMakes]);

    // useEffect(() => {
    //     // Filters updated, clear the data
    //     setData([]);
    //     setExportMode(undefined);
    // }, [currentFilter]);

    const renderSettingsAndFilters = () => {
        return (
            <div className="flex flex-col gap-2">
                <Typography.Title level={4}>Export Settings</Typography.Title>
                <Typography.Text>Country: {getFirstFilterValue("filterCountryId")}</Typography.Text>
                <Typography.Text>Channel: {getFirstFilterValue("filterChannel1")}</Typography.Text>
                <Typography.Text>Duration: {getFirstFilterValue("filterDuration")}</Typography.Text>
                <Typography.Text>Export Type: {tabs[tabindex].label}</Typography.Text>
                <Typography.Text>Target Deposit: {selectedAdjDeposit}</Typography.Text>
                <Typography.Text>Services: {serviceMessage}</Typography.Text>
                {getFiltersForFootnote() && (
                    <>
                        <Typography.Title level={5} className="mt-4">
                            Applied Filters
                        </Typography.Title>
                        {getFiltersForFootnote()}
                    </>
                )}
            </div>
        );
    };

    const handleExportClick = (exportMode) => {
        setShowConfirmModal(true);
        setExportMode(exportMode);
    };

    return (
        <div className="flex flex-col items-stretch gap-4">
            <>
                <div className="flex justify-between flex-row">
                    {data.length > 0 && (
                        <div className="flex justify-end mb-4 gap-2">
                            {exportModes.map((elem) => (
                                <button key={elem.id} className="flex items-center px-8 py-1 text-sm font-medium bg-indigo-700 text-white hover:bg-indigo-500 hover:text-white border border-indigo-900 rounded" onClick={() => handleExportClick(elem)}>
                                    {elem.title}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
                {status !== "success" ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {["ready", "validated"].includes(incrementalLoadStatus) && data.length <= 0 && (
                            <IncrementalExport execDefinition={execDefinition} corrMakes={corrMakes} toTransform={tabs[tabindex].transformHandler} onComplete={(data) => generateTableParams(data)} basketList={basketList} servicemessage={serviceMessage} />
                        )}
                        {data.length > 0 && (
                            <>
                                <div className="flex items-center">
                                    <InformationCircleIcon className="h-5 w-5 text-gray-700 mr-2" aria-hidden="true" />
                                    <span className="text-base font-semibold text-center text-gray-700">This table shows a sample of the file. To download the entire file, please click on one of the "Export" buttons</span>
                                </div>
                                <ExportTable data={data} columns={columns} type={tabs[tabindex].label} />
                            </>
                        )}
                        {confirmedExportMode &&
                            (incrementalLoadStatus === "ready" ? (
                                <Modal
                                    open={confirmedExportMode && incrementalLoadStatus === "ready"}
                                    width={800}
                                    onCancel={() => setConfirmedExportMode(undefined)}
                                    onOk={() => {
                                        setIncrementalLoad("validated");
                                        setConfirmedExportMode(undefined);
                                    }}
                                >
                                    <div className="flex flex-col items-stretch justify-center gap-4">
                                        <span className="text-lg font-semibold text-gray-500">Due to the important amount of Data, the extraction may take several minutes</span>
                                        <span className="text-base font-medium text-gray-500">Are you sure to proceed?</span>
                                    </div>
                                </Modal>
                            ) : incrementalLoadStatus === "validated" ? (
                                <IncrementalExportFullCatalog
                                    corrMakes={corrMakes}
                                    execDefinition={execDefinition}
                                    toTransform={tabs[tabindex].transformHandler}
                                    onComplete={(data) =>
                                        exportMode.download(data, tabs[tabindex].label).then((result) => {
                                            setConfirmedExportMode(undefined);
                                        })
                                    }
                                    basketList={basketList}
                                    servicemessage={serviceMessage}
                                />
                            ) : (
                                <ExportFullCatalog
                                    execDefinition={execDefinition}
                                    toTransform={tabs[tabindex].transformHandler}
                                    onComplete={(data) =>
                                        exportMode.download(data, tabs[tabindex].label).then((result) => {
                                            setConfirmedExportMode(undefined);
                                        })
                                    }
                                    basketList={basketList}
                                    servicemessage={serviceMessage}
                                />
                            ))}
                    </>
                )}
            </>
            <Modal
                open={showConfirmModal}
                onCancel={() => {
                    setShowConfirmModal(false);
                    setExportMode(undefined);
                }}
                footer={[
                    <Button key="cancel" onClick={() => setShowConfirmModal(false)}>
                        Cancel
                    </Button>,
                    <Button
                        key="confirm"
                        type="primary"
                        onClick={() => {
                            setConfirmedExportMode(exportMode);
                            setShowConfirmModal(false);
                        }}
                    >
                        Confirm and Export
                    </Button>,
                ]}
                width={800}
                title="Confirm Export Settings"
            >
                {renderSettingsAndFilters()}
            </Modal>
        </div>
    );
}
export default Export;
